import Vue from 'vue'
import NuxtLoading from './components/nuxt-loading.vue'

import '../node_modules/sanitize.css/sanitize.css'

import '../node_modules/font-awesome/scss/font-awesome.scss'

import '../src/assets/stylesheets/main.scss'

import '../node_modules/bootstrap-vue/dist/bootstrap-vue.css'

import '../node_modules/vue-multiselect/dist/vue-multiselect.min.css'

import '../src/assets/stylesheets/custom.css'

import _699ab4ec from '../src/layouts/belowtheline.vue'
import _6f6c098b from '../src/layouts/default.vue'
import _77430317 from '../src/layouts/empty.vue'
import _34b6ee85 from '../src/layouts/loading-later.vue'
import _27691486 from '../src/layouts/loading.vue'

const layouts = { "_belowtheline": _699ab4ec,"_default": _6f6c098b,"_empty": _77430317,"_loading-later": _34b6ee85,"_loading": _27691486 }

export default {
  head: {"titleTemplate":function (titleChunk) {
            return titleChunk
                ? titleChunk + " | Car4Cash"
                : 'รีไฟแนนซ์รถ สินเชื่อรถ สินเชื่อบิ๊กไบค์ สินเชื่อมอเตอร์ไซค์ | Car4Cash';
        },"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no"},{"hid":"description","name":"description","content":"ขอสินเชื่อรถยนต์ รีไฟแนนซ์รถ สินเชื่อบิ๊กไบค์หรือสินเชื่อมอเตอร์ไซค์แบบไม่โอนเล่มทะเบียนหรือจำนำรถทำได้ไหม เลือกดอกเบี้ยแบบไหนคุ้มที่สุด เรามีคำตอบให้"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"favicon.ico"}],"script":[{"defer":true,"src":"https:\u002F\u002Fcdn-apac.onetrust.com\u002Fconsent\u002Ffd88598f-bb24-4652-bd5c-098c1b1446eb-test\u002FOtAutoBlock.js"},{"defer":true,"src":"https:\u002F\u002Fcdn-apac.onetrust.com\u002Fscripttemplates\u002FotSDKStub.js","data-domain-script":"fd88598f-bb24-4652-bd5c-098c1b1446eb-test","data-document-language":"true","charset":"UTF-8"},{"defer":true,"src":"onetrust.js"},{"async":true,"src":"https:\u002F\u002Fwww.datadoghq-browser-agent.com\u002Fdatadog-logs-us.js"},{"async":true,"src":"https:\u002F\u002Fjs.adsrvr.org\u002Fup_loader.1.1.0.js"},{"src":"\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id=GTM-KZD2NRB&l=dataLayer","async":true}],"style":[],"noscript":[{"hid":"gtm-noscript","innerHTML":"\u003Ciframe src=\"\u002F\u002Fwww.googletagmanager.com\u002Fns.html?id=GTM-KZD2NRB&l=dataLayer\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\"\u003E\u003C\u002Fiframe\u003E","pbody":true}],"__dangerouslyDisableSanitizersByTagID":{"gtm-noscript":["innerHTML"]}},

  render(h, props) {
    const loadingEl = h('NuxtLoading', { ref: 'loading' })
    const layoutEl = h(this.layout || 'nuxt')
    const templateEl = h('div', {
      domProps: {
        id: '__layout'
      },
      key: this.layoutName
    }, [ layoutEl ])

    const transitionEl = h('transition', {
      props: {
        name: 'layout',
        mode: 'out-in'
      },
      on: {
        beforeEnter(el) {
          // Ensure to trigger scroll event after calling scrollBehavior
          window.$nuxt.$nextTick(() => {
            window.$nuxt.$emit('triggerScroll')
          })
        }
      }
    }, [ templateEl ])

    return h('div', {
      domProps: {
        id: '__nuxt'
      }
    }, [loadingEl, transitionEl])
  },
  data: () => ({
    isOnline: true,
    layout: null,
    layoutName: ''
  }),
  beforeCreate() {
    Vue.util.defineReactive(this, 'nuxt', this.$options.nuxt)
  },
  created() {
    // Add this.$nuxt in child instances
    Vue.prototype.$nuxt = this
    // add to window so we can listen when ready
    if (process.client) {
      window.$nuxt = this
      this.refreshOnlineStatus()
      // Setup the listeners
      window.addEventListener('online', this.refreshOnlineStatus)
      window.addEventListener('offline', this.refreshOnlineStatus)
    }
    // Add $nuxt.error()
    this.error = this.nuxt.error
  },

  mounted() {
    this.$loading = this.$refs.loading
  },
  watch: {
    'nuxt.err': 'errorChanged'
  },

  computed: {
    isOffline() {
      return !this.isOnline
    }
  },
  methods: {
    refreshOnlineStatus() {
      if (process.client) {
        if (typeof window.navigator.onLine === 'undefined') {
          // If the browser doesn't support connection status reports
          // assume that we are online because most apps' only react
          // when they now that the connection has been interrupted
          this.isOnline = true
        } else {
          this.isOnline = window.navigator.onLine
        }
      }
    },

    errorChanged() {
      if (this.nuxt.err && this.$loading) {
        if (this.$loading.fail) this.$loading.fail()
        if (this.$loading.finish) this.$loading.finish()
      }
    },

    setLayout(layout) {
      if (!layout || !layouts['_' + layout]) {
        layout = 'default'
      }
      this.layoutName = layout
      this.layout = layouts['_' + layout]
      return this.layout
    },
    loadLayout(layout) {
      if (!layout || !layouts['_' + layout]) {
        layout = 'default'
      }
      return Promise.resolve(layouts['_' + layout])
    }
  },
  components: {
    NuxtLoading
  }
}
