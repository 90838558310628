import Vue from 'vue'
import Meta from 'vue-meta'
import { createRouter } from './router.js'
import NoSsr from './components/no-ssr.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../src/layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_googletagmanager_207cd374 from 'nuxt_plugin_googletagmanager_207cd374' // Source: ./google-tag-manager.js (mode: 'client')
import nuxt_plugin_nuxtenv_687a1dd7 from 'nuxt_plugin_nuxtenv_687a1dd7' // Source: ./nuxt-env.js (mode: 'all')
import nuxt_plugin_axios_1c712284 from 'nuxt_plugin_axios_1c712284' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_57905b34 from 'nuxt_plugin_cookieuniversalnuxt_57905b34' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_filters_98405076 from 'nuxt_plugin_filters_98405076' // Source: ../src/plugins/filters (mode: 'all')
import nuxt_plugin_inputmasking_03454750 from 'nuxt_plugin_inputmasking_03454750' // Source: ../src/plugins/input-masking.ts (mode: 'all')
import nuxt_plugin_vuelidate_4be42f5c from 'nuxt_plugin_vuelidate_4be42f5c' // Source: ../src/plugins/vuelidate.ts (mode: 'all')
import nuxt_plugin_time_cf04e5ec from 'nuxt_plugin_time_cf04e5ec' // Source: ../src/plugins/time.ts (mode: 'all')
import nuxt_plugin_featuretoggle_4d6d1ee8 from 'nuxt_plugin_featuretoggle_4d6d1ee8' // Source: ../src/plugins/feature-toggle.ts (mode: 'all')
import nuxt_plugin_carryanalyticparams_0fc2f40f from 'nuxt_plugin_carryanalyticparams_0fc2f40f' // Source: ../src/plugins/carry-analytic-params.ts (mode: 'all')
import nuxt_plugin_splashpage_0a12b464 from 'nuxt_plugin_splashpage_0a12b464' // Source: ../src/plugins/splash-page.ts (mode: 'all')
import nuxt_plugin_googleanalytics_2bcb2c76 from 'nuxt_plugin_googleanalytics_2bcb2c76' // Source: ../src/plugins/google-analytics.ts (mode: 'client')
import nuxt_plugin_googleanalytics4_593482e1 from 'nuxt_plugin_googleanalytics4_593482e1' // Source: ../src/plugins/google-analytics4.ts (mode: 'client')
import nuxt_plugin_hotjar_6900f5d3 from 'nuxt_plugin_hotjar_6900f5d3' // Source: ../src/plugins/hotjar.js (mode: 'client')
import nuxt_plugin_amplitudeanalytics_71acdde7 from 'nuxt_plugin_amplitudeanalytics_71acdde7' // Source: ../src/plugins/amplitude-analytics.ts (mode: 'client')
import nuxt_plugin_datadog_19a1b94a from 'nuxt_plugin_datadog_19a1b94a' // Source: ../src/plugins/datadog.js (mode: 'client')
import nuxt_plugin_facebookpixel_715b75ed from 'nuxt_plugin_facebookpixel_715b75ed' // Source: ../src/plugins/facebookpixel.js (mode: 'client')
import nuxt_plugin_line_1d4c6afa from 'nuxt_plugin_line_1d4c6afa' // Source: ../src/plugins/line.ts (mode: 'client')

// Component: <NoSsr>
Vue.component(NoSsr.name, NoSsr)

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>`
Vue.component(Nuxt.name, Nuxt)

// vue-meta configuration
Vue.use(Meta, {
  keyName: 'head', // the component option name that vue-meta looks for meta info on.
  attribute: 'data-n-head', // the attribute name vue-meta adds to the tags it observes
  ssrAttribute: 'data-n-head-ssr', // the attribute name that lets vue-meta know that meta info has already been server-rendered
  tagIDKeyName: 'hid' // the property name that vue-meta uses to determine whether to overwrite or append a tag
})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext) {
  const router = await createRouter(ssrContext)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  const registerModule = store.registerModule
  store.registerModule = (path, rawModule, options) => registerModule.call(store, path, rawModule, Object.assign({ preserveState: process.client }, options))

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    router,
    store,
    nuxt: {
      defaultTransition,
      transitions: [ defaultTransition ],
      setTransitions(transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [ transitions ]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },
      err: null,
      dateErr: null,
      error(err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        const nuxt = this.nuxt || this.$options.nuxt
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) ssrContext.nuxt.error = err
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    route,
    next,
    error: app.nuxt.error.bind(app),
    store,
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  const inject = function (key, value) {
    if (!key) throw new Error('inject(key, value) has no key provided')
    if (typeof value === 'undefined') throw new Error('inject(key, value) has no value provided')
    key = '$' + key
    // Add into app
    app[key] = value

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) return
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Vue.prototype.hasOwnProperty(key)) {
        Object.defineProperty(Vue.prototype, key, {
          get() {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Plugin execution

  if (process.client && typeof nuxt_plugin_googletagmanager_207cd374 === 'function') {
    await nuxt_plugin_googletagmanager_207cd374(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtenv_687a1dd7 === 'function') {
    await nuxt_plugin_nuxtenv_687a1dd7(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_1c712284 === 'function') {
    await nuxt_plugin_axios_1c712284(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_57905b34 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_57905b34(app.context, inject)
  }

  if (typeof nuxt_plugin_filters_98405076 === 'function') {
    await nuxt_plugin_filters_98405076(app.context, inject)
  }

  if (typeof nuxt_plugin_inputmasking_03454750 === 'function') {
    await nuxt_plugin_inputmasking_03454750(app.context, inject)
  }

  if (typeof nuxt_plugin_vuelidate_4be42f5c === 'function') {
    await nuxt_plugin_vuelidate_4be42f5c(app.context, inject)
  }

  if (typeof nuxt_plugin_time_cf04e5ec === 'function') {
    await nuxt_plugin_time_cf04e5ec(app.context, inject)
  }

  if (typeof nuxt_plugin_featuretoggle_4d6d1ee8 === 'function') {
    await nuxt_plugin_featuretoggle_4d6d1ee8(app.context, inject)
  }

  if (typeof nuxt_plugin_carryanalyticparams_0fc2f40f === 'function') {
    await nuxt_plugin_carryanalyticparams_0fc2f40f(app.context, inject)
  }

  if (typeof nuxt_plugin_splashpage_0a12b464 === 'function') {
    await nuxt_plugin_splashpage_0a12b464(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googleanalytics_2bcb2c76 === 'function') {
    await nuxt_plugin_googleanalytics_2bcb2c76(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googleanalytics4_593482e1 === 'function') {
    await nuxt_plugin_googleanalytics4_593482e1(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_hotjar_6900f5d3 === 'function') {
    await nuxt_plugin_hotjar_6900f5d3(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_amplitudeanalytics_71acdde7 === 'function') {
    await nuxt_plugin_amplitudeanalytics_71acdde7(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_datadog_19a1b94a === 'function') {
    await nuxt_plugin_datadog_19a1b94a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_facebookpixel_715b75ed === 'function') {
    await nuxt_plugin_facebookpixel_715b75ed(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_line_1d4c6afa === 'function') {
    await nuxt_plugin_line_1d4c6afa(app.context, inject)
  }

  // If server-side, wait for async component to be resolved first
  if (process.server && ssrContext && ssrContext.url) {
    await new Promise((resolve, reject) => {
      router.push(ssrContext.url, resolve, () => {
        // navigated to a different route in router guard
        const unregister = router.afterEach(async (to, from, next) => {
          ssrContext.url = to.fullPath
          app.context.route = await getRouteData(to)
          app.context.params = to.params || {}
          app.context.query = to.query || {}
          unregister()
          resolve()
        })
      })
    })
  }

  return {
    app,
    store,
    router
  }
}

export { createApp, NuxtError }
